<ion-app>
  <ion-menu side="start" class="menu" content-id="main">
    <ion-header>
      <ion-toolbar>
        <ion-title>
          <span *ngIf="!authService.isAuthenticated()">{{ 'Menu' | translate }}</span>
          <span *ngIf="authService.isAuthenticated()">{{'Hi'|translate}}, {{authService.userProfile?.name}}</span>
        </ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content style="background-color: white; --background: white;">
        <ion-list>
          <ion-menu-toggle auto-hide="false">
            <ion-item (click)="navigationService.openHomePage()">
              <ion-label><fa-icon [icon]="['fas', 'home']"></fa-icon>&nbsp; {{ 'Home' | translate }}</ion-label>
            </ion-item>

            <ion-item-divider><ion-label>{{'Dictation'|translate}}</ion-label></ion-item-divider>
            <ion-item (click)="navigationService.editDictation(null, 'Start')">
              <ion-label><fa-icon [icon]="['fas', 'rocket']"></fa-icon>&nbsp; {{ 'Quick Start' | translate }}</ion-label>
            </ion-item>
            <ion-item (click)="navigationService.openSearchDictation()">
              <ion-label><fa-icon [icon]="['fas', 'search']"></fa-icon>&nbsp; {{ 'Search Dictation' | translate }}</ion-label>
            </ion-item>
            <ion-item *ngIf="authService.isAuthenticated()" (click)="navigationService.openMemberHome()">
              <ion-label><fa-icon [icon]="['fas', 'list']"></fa-icon>&nbsp; {{ 'My Dictation' | translate }}</ion-label>
            </ion-item>
            <ion-item *ngIf="authService.isAuthenticated()" (click)="navigationService.editDictation()">
              <ion-label><fa-icon [icon]="['fas', 'edit']"></fa-icon>&nbsp; {{ 'Create Dictation' | translate }}</ion-label>
            </ion-item>

            <ion-item-divider><ion-label>{{'English Exercise'|translate}}</ion-label></ion-item-divider>
            <ion-item (click)="navigationService.openVocabularyStarter()">
              <ion-label><fa-icon icon="book"></fa-icon>&nbsp; {{ 'Vocabulary Practice' | translate }}</ion-label>
            </ion-item>
            <ion-item *ngIf="authService.isAuthenticated()" (click)="navigationService.openMemberHome('vocabulary')">
              <ion-label><fa-icon [icon]="['fas', 'list']"></fa-icon>&nbsp; {{ 'My Vocabulary' | translate }}</ion-label>
            </ion-item>

            <ion-item-divider><ion-label>{{ 'Account' | translate }}</ion-label></ion-item-divider>
            <ion-item *ngIf="!authService.isAuthenticated()" (click)="authService.login()">
              <ion-label><fa-icon [icon]="['fas', 'sign-in-alt']" item-start></fa-icon>&nbsp; {{ 'Login' | translate }}</ion-label>
            </ion-item>
            <ion-item *ngIf="!authService.isAuthenticated()" (click)="authService.signUp()">
              <fa-icon [icon]="['fas', 'user-plus']" item-start></fa-icon>&nbsp; {{ 'Sign Up' | translate }}
            </ion-item>
            <ion-item *ngIf="authService.isAuthenticated()" (click)="navigationService.openAccountPage()">
              <fa-icon [icon]="['fas', 'user']" item-start></fa-icon>&nbsp; {{ 'My Account' | translate }}
            </ion-item>
            <ion-item *ngIf="authService.isAuthenticated()" (click)="authService.logout()">
              <fa-icon [icon]="['fas', 'sign-out-alt']" item-start></fa-icon>&nbsp; {{ 'Logout' | translate }}
            </ion-item>

            <ion-item-divider><ion-label>{{ 'Language' | translate }}</ion-label></ion-item-divider>
            <ion-item (click)="navigationService.changeLanguage('en')"><ion-label>Eng</ion-label></ion-item>
            <ion-item (click)="navigationService.changeLanguage('zh-Hant')"><ion-label>繁體</ion-label></ion-item>
            <ion-item (click)="navigationService.changeLanguage('zh-Hans')"><ion-label>简体</ion-label></ion-item>

            <ion-item-divider></ion-item-divider>
            <ion-item (click)="navigationService.openContactUs()">
              <ion-label>{{'Contact Us'|translate}}</ion-label>
              <fa-icon class="ion-float-end" [icon]="['fas', 'external-link-alt']"></fa-icon>
            </ion-item>
            <ion-item *ngIf="platform.is('cordova') || platform.is('capacitor')" (click)="navigationService.openFunFunSpell()">
              <ion-label>{{'More Exercises at'|translate}}<br/>funfunspell.com</ion-label>
              <fa-icon class="ion-float-end" [icon]="['fas', 'external-link-alt']"></fa-icon>
            </ion-item>
          </ion-menu-toggle>
        </ion-list>
    </ion-content>

  </ion-menu>

  <ion-router-outlet id="main" [swipeGesture]="false"></ion-router-outlet>

  <ion-alert [isOpen]="networkAlertConfig.showAlert"
             header="{{'Connection Error' | translate}}"
             subHeader="{{'Please connect to network or Try again later' | translate}}"
             [buttons]="networkAlertConfig.buttons"
             (didDismiss)="closeNetworkAlert()"></ion-alert>
</ion-app>
